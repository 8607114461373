import React, { Component } from 'react';

export default class Header extends Component {
	MoveHeader() {
		const hdr = document.querySelector('.s-header');
		const hero = document.querySelector('#hero');
		let triggerHeight;

		if (!(hdr && hero)) return;

		setTimeout(function () {
			triggerHeight = hero.offsetHeight - 170;
		}, 300);

		window.addEventListener('scroll', function () {
			let loc = window.scrollY;

			if (loc > triggerHeight) {
				hdr.classList.add('sticky');
			} else {
				hdr.classList.remove('sticky');
			}

			if (loc > triggerHeight + 20) {
				hdr.classList.add('offset');
			} else {
				hdr.classList.remove('offset');
			}

			if (loc > triggerHeight + 150) {
				hdr.classList.add('scrolling');
			} else {
				hdr.classList.remove('scrolling');
			}
		});
	}

	MobileMenu() {
		const toggleButton = document.querySelector('.s-header__menu-toggle');
		const headerNavWrap = document.querySelector('.s-header__nav-wrap');
		const siteBody = document.querySelector('body');

		if (!(toggleButton && headerNavWrap)) return;

		toggleButton.addEventListener('click', function (event) {
			event.preventDefault();
			toggleButton.classList.toggle('is-clicked');
			siteBody.classList.toggle('menu-is-open');
		});

		headerNavWrap.querySelectorAll('.s-header__nav a').forEach(function (link) {
			link.addEventListener('click', function (evt) {
				// at 800px and below
				if (window.matchMedia('(max-width: 800px)').matches) {
					toggleButton.classList.toggle('is-clicked');
					siteBody.classList.toggle('menu-is-open');
				}
			});
		});

		window.addEventListener('resize', function () {
			// above 800px
			if (window.matchMedia('(min-width: 801px)').matches) {
				if (siteBody.classList.contains('menu-is-open'))
					siteBody.classList.remove('menu-is-open');
				if (toggleButton.classList.contains('is-clicked'))
					toggleButton.classList.remove('is-clicked');
			}
		});
	}

	ScrollSpy() {
		const sections = document.querySelectorAll('.target-section');

		// Add an event listener listening for scroll
		window.addEventListener('scroll', navHighlight);

		function navHighlight() {
			// Get current scroll position
			let scrollY = window.pageYOffset;

			// Loop through sections to get height(including padding and border),
			// top and ID values for each
			sections.forEach(function (current) {
				const sectionHeight = current.offsetHeight;
				const sectionTop = current.offsetTop - 50;
				const sectionId = current.getAttribute('id');

				/* If our current scroll position enters the space where current section
				 * on screen is, add .current class to parent element(li) of the thecorresponding
				 * navigation link, else remove it. To know which link is active, we use
				 * sectionId variable we are getting while looping through sections as
				 * an selector
				 */
				const navLink = document.querySelector(
					'.s-header__nav a[href*=' + sectionId + ']'
				);
				if (navLink) {
					if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
						navLink.parentNode.classList.add('current');
					} else {
						navLink.parentNode.classList.remove('current');
					}
				}
			});
		}
	}

	componentDidMount() {
		this.MoveHeader();
		this.MobileMenu();
		this.ScrollSpy();
	}

	render() {
		return (
			<header className="s-header">
				<div className="row s-header__nav-wrap">
					<nav className="s-header__nav">
						<ul>
							<li className="current">
								<a className="smoothscroll" href="#hero">
									Home
								</a>
							</li>
							<li>
								<a className="smoothscroll" href="#about">
									About
								</a>
							</li>
							<li>
								<a
									className="smoothscroll"
									href="resume/Hosam_2025_resume.pdf"
									target="_blank">
									Resume
								</a>
							</li>
							<li>
								<a className="smoothscroll" href="#cv">
									CV
								</a>
							</li>
							<li>
								<a className="smoothscroll" href="#contact">
									Say Hello
								</a>
							</li>
						</ul>
					</nav>
				</div>
				<a className="s-header__menu-toggle" href="#0" title="Menu">
					<span className="s-header__menu-icon" />
				</a>
			</header>
		);
	}
}
